import APIService from '@/services/api-service'
const resource = '/expense'

class CategoryService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public create (data: any) {
    return this.connector.post(`${resource}/create`, data)
  }

  public createCategory (data: any) {
    return this.connector.post(`${resource}/create/category`, data)
  }

  public getCategory (id: number) {
    return this.connector.get(`${resource}/category/${id}`)
  }

  public update (data: any) {
    return this.connector.put(`${resource}/update/${data.id}`, data)
  }

  public getVesselCodes () {
    return this.connector.get('/vessel/codes')
  }

  public getCategories () {
    return this.connector.get(`${resource}/categories`)
  }

  public getAllCategories () {
    return this.connector.get(`${resource}/category/all`)
  }

  public getParentCategories () {
    return this.connector.get(`${resource}/category/parent`)
  }
}

export default new CategoryService()
