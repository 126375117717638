
import {
  Component,
  Vue
} from 'vue-property-decorator'

import {
  AppModule
} from '@/store/modules/AppModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  required
} from 'vuelidate/lib/validators'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import VoucherService from './services/payment-service'
  @Component({
    name: 'JobMode',
    components: {
      // CoolSelect
    }
  })
export default class JobMode extends Vue {
    private isEdit = false
    protected fleets: any = []
    protected vessels: any = []
    protected trucks: any = []
    protected items: any = []
    protected fields: any = [{
      key: '#',
      label: '#',
      thStyle: {
        width: '3%'
      },
      class: 'text-right'
    },
    {
      key: 'voucher_code',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'description',
      thStyle: {
        width: '40%'
      }
    },
    {
      key: 'unit_price',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'currency',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'quantity',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'amount',
      thStyle: {
        width: '10%'
      }
    }
    // {
    //   key: 'action',
    //   label: '',
    //   thStyle: {
    //     width: '10%'
    //   },
    //   class: 'text-center'
    // }
    ]

    $v: Vuelidate

    mounted () {
      this.items = this.items.map(items => ({
        ...items,
        isEdit: true
      }))
    }

    created () {
      this.boot()
    }

    private boot () {
      this.populate()
      this.items.push({
        id: null,
        voucher_code: null,
        description: null,
        unit_price: null,
        currency: null,
        quantity: null,
        amount: null,
        created_user: AuthModule.user.id
      })
    }

    private select (selectAll: boolean) {
      for (const i in this.items) {
        if (selectAll) this.items[i].selected = true
        else this.items[i].selected = false
      }
    }

    protected isNumber ($event: any) {
      const charCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    }

    private populate () {
      VoucherService.getVoucher().then((response) => {
        // if (response.data.status === 'empty') return false
        this.items = response.data.map(item => ({
          ...item,
          isEdit: false
        }))
      })
    }

    private editRowHandler (data: any) {
      this.items[data.index].isEdit = !this.items[data.index].isEdit
    }

    protected addLine () {
      this.items = this.items.map(item => ({
        ...item,
        isEdit: false
      }))
      // if (this.items.length >= this.vessels.length) return false
      this.items.push({
        isEdit: true,
        id: null,
        voucher_code: null,
        description: null,
        unit_price: null,
        currency: null,
        quantity: null,
        amount: null,
        created_user: AuthModule.user.id
      })
    }

    protected deleteLine (index: number) {
      this.items.splice(index, 1)
    }

    private setError (error: any) {
      throw new Error(error)
    }

    protected cancel () {
      if (this.isEdit) return false
      this.items = []
      this.addLine()
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }

    protected async print () {
      await this.$htmlToPaper('printList')
    }
}

