
import {
  Component,
  Vue
} from 'vue-property-decorator'
import CrewService from './services/crew-service'
import ExpenseService from './services/expense-service'

  @Component({
    name: 'VesselCrew',
    components: {
    }
  })
export default class VesselCrew extends Vue {
    private fields = ['id', 'name', 'rank', 'vessel', 'joining_date', 'sign_off_date', 'version', 'date', 'actions']
    private members: any = []
    private vessels: any = []
    private filter = null
    private perPage = 10
    private currentPage = 1

    created () {
      this.populateVessels()
      this.populateCrewMembers()
    }

    get rows () {
      return this.members.length
    }

    private populateVessels () {
      ExpenseService.getVesselCodes().then((response: any) => {
        response.data.forEach((element: { id: any; code: string; name: string }) => {
          this.vessels.push({
            value: element.id,
            text: element.code + ' - ' + element.name
          })
        })
      }).catch(error => {
        console.log(error)
      })
    }

    private async populateCrewMembers () {
      const response = await CrewService.getCrewMembers()
      this.members = response.data
    }

    private edit (id: any) {
      this.$router.push({ name: 'CrewMember', params: { id: id } })
    }
}

