
import { Component, Vue } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import CategoryService from './services/category-service'
@Component({
  name: 'Category'
})
export default class Ledger extends Vue {
    private isEdit = false
    private filter = null
    private perPage = 10
    private currentPage = 1
    private modalTitle = ''
    private vessels: any = []
    private fields = ['id', 'code', 'description', 'source_type', 'version', 'created_date', 'actions']
    private catype: any = null
    private parentCategory: any = null
    private parentCategories: any = []
    private categories = []
    private categorySelector: any = [{
      value: 0,
      text: 'Parent'
    },
    {
      value: 1,
      text: 'Child'
    }
    ]

    private categoryTypes: any = [{
      value: 'vessel',
      text: 'Vessel'
    },
    {
      value: 'company',
      text: 'Company'
    }
    ]

    private category = {
      id: null,
      code: null,
      source_type: null,
      source_id: 1,
      description: null,
      parent_id: 0
    }

    get rows () {
      return this.categories.length
    }

    @Validations()
    validations = {
      category: {
        code: { required, minLength: minLength(3) },
        description: { required, minLength: minLength(5) }
      }

    }

    created () {
      this.populate()
      this.populateParentCategories()
      this.populateVessels()
    }

    private showAddModal () {
      this.modalTitle = 'Add Category';
      (this.$refs.categoryModal as any).show()
    }

    private populate () {
      CategoryService.getAllCategories().then((reponse: any) => {
        this.categories = reponse.data
      }).catch(error => {
        console.log(error)
      })
    }

    private populateParentCategories () {
      CategoryService.getParentCategories().then((response: any) => {
        response.data.forEach(element => {
          this.parentCategories.push({
            value: element.id,
            text: element.category
          })
        })
      }).catch(error => {
        console.log(error)
      })
    }

    private populateVessels () {
      CategoryService.getVesselCodes().then((response: any) => {
        response.data.forEach((element: { id: any; code: string; name: string }) => {
          this.vessels.push({
            value: element.id,
            text: element.code + ' - ' + element.name
          })
        })
      }).catch(error => {
        console.log(error)
      })
    }

    private edit (id: number) {
      this.modalTitle = 'Edit Category';
      (this.$refs.categoryModal as any).show()
      this.isEdit = true
      CategoryService.getCategory(id).then((reponse: any) => {
        this.category = reponse.data
        this.parentCategory = this.category.parent_id
        if (this.category.parent_id === 0) {
          this.catype = 0
        } else {
          this.catype = 1
        }
      }).catch(error => {
        console.log(error)
      })
    }

    private save () {
      if (this.isEdit) {
        this.update()
      } else {
        this.create()
      }
    }

    private create () {
      if (this.catype === 0) {
        this.category.parent_id = 0
      } else {
        this.category.parent_id = this.parentCategory
      }

      if (this.category.source_type === 'company') {
        this.category.source_id = 1
      }

      CategoryService.createCategory(this.category).then(() => {
        this.populate()
        this.close()
      }).catch(error => {
        alert(error.data.message)
      })
    }

    private update () {
      CategoryService.update(this.category).then(() => {
        this.populate()
        this.close();
        (this.$refs.categoryModal as any).hide()
      }).catch(error => {
        alert(error.data.message)
      })
    }

    private clear () {
      if (this.isEdit) return false
      this.reset(this.category)
    }

    private close () {
      this.isEdit = false
      this.catype = null
      this.parentCategory = null
      this.reset(this.category)
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}
