
import {
  Component,
  Vue
} from 'vue-property-decorator'

import InquiryService from '../services/inquiry-service'
import {
  AppModule
} from '@/store/modules/AppModule'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  Validations
} from 'vuelidate-property-decorators'
import {
  Vuelidate
} from 'vuelidate'
import {
  required,
  minLength,
  maxLength
} from 'vuelidate/lib/validators'
import Currencies from '@/data/currency.json'
import UomJobs from '@/data/uom-jobs.json'
  @Component({
    name: 'InquiryForm',
    components: {
      // CoolSelect
    }
  })
export default class InquiryForm extends Vue {
    private isEditForm = false
    private lines: any = []
    private inquiryNumbers: any = []
    private clientCodes: any = []
    private uoms = UomJobs
    private currencies = Currencies
    private portNames: any = []
    public services: any = []

    $v: Vuelidate

    @Validations()
    validations = {
      header: {
        client_code: {
          required
        },
        category: {
          required
        },
        vessel_name: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(90)
        },
        vessel_arrival_date: {
          required
        },
        voyage_number: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(20)
        },
        port_name: {
          required
        }
      }
    }

    private header: any = {
      id: null,
      client_code: null,
      category: null,
      vessel_name: null,
      vessel_arrival_date: null,
      voyage_number: null,
      port_name: null,
      inquiry_date: null,
      inquiry_number: null,
      status: null,
      created_user: null
    }

    private fields = [{
      key: 'service',
      label: 'Service Type',
      thStyle: {
        width: '30%'
      }
    },
    {
      key: 'volume',
      label: 'Volume',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'unit_of_measurement',
      label: 'UoM',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'rate',
      label: 'Rate',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'refund',
      label: 'Refund',
      thStyle: {
        width: '10%'
      }
    },
    {
      key: 'currency',
      label: 'Currency',
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '10%'
      },
      class: 'text-center'
    }
    ]

    private jobCategories = [{
      value: 'Own Job',
      text: 'Own Job'
    },
    {
      value: 'Hire',
      text: 'Hire'
    }
    ]

    private statuses = [{
      text: 'Cancel',
      value: 'cancel'
    },
    {
      text: 'Confirmed',
      value: 'confirmed'
    }
    ]

    mounted () {
      this.lines = this.lines.map(lines => ({
        ...lines,
        isEdit: true
      }))
    }

    created () {
      this.boot()
    }

    private boot () {
      this.populateClientCodes()
      this.populatePortNames()
      this.populateServices()
      if (this.$route.params.id) {
        this.isEditForm = true
        this.populateInquiry(this.$route.params.id)
      } else {
        this.isEditForm = false
      }
      this.lines.push({
        id: null,
        service: {},
        volume: null,
        unit_of_measurement: null,
        rate: null,
        refund: null,
        currency: null,
        remarks: null,
        created_user: AuthModule.user.id
      })
    }

    private select (selectAll: boolean) {
      for (const i in this.lines) {
        if (selectAll) this.lines[i].selected = true
        else this.lines[i].selected = false
      }
    }

    private isNumber ($event: any) {
      const charCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        $event.preventDefault()
      } else {
        return true
      }
    }

    private async populateInquiry (id: any) {
      const response = await InquiryService.getInquiry(id)
      const headerValues = Object.keys(this.header)
      Object.keys(response.data).filter(key => headerValues.includes(key))
        .forEach(key => {
          this.header[key] = response.data[key]
          delete response.data[key]
        })
      this.lines = response.data.lines.map(lines => ({
        ...lines,
        isEdit: false
      }))
    }

    private async getInquiryByNumber (value) {
      const response = await InquiryService.getInquiryByNumber(value)
      const headerValues = Object.keys(this.header)
      Object.keys(response.data).filter(key => headerValues.includes(key))
        .forEach(key => {
          this.header[key] = response.data[key]
          delete response.data[key]
        })
      this.lines = response.data.lines.map(lines => ({
        ...lines,
        isEdit: false
      }))
    }

    private async populateClientCodes () {
      const response = await InquiryService.clientCodes()
      this.clientCodes = response.data
    }

    public async populateServiceRate (data) {
      const response = await InquiryService.getSerivceRate(this.header.client_code, data.item.service.id)
      this.lines[data.index].rate = response.data.rate
      this.lines[data.index].refund = response.data.refund
      this.lines[data.index].currency = response.data.currency
    }

    private async populatePortNames () {
      const response = await InquiryService.getPortNames()
      response.data.forEach((element: {
        name: any
      }) => {
        this.portNames.push({
          value: element.name,
          text: element.name
        })
      })
    }

    private populateServices () {
      InquiryService.getServices().then((response) => {
        response.data.forEach((element: {
          id: any;type: any
        }) => {
          this.services.push({
            value: {
              id: element.id,
              type: element.type
            },
            text: element.type
          })
        })
      }).catch(error => {
        console.log(error)
      })
    }

    protected editRowHandler (data: any) {
      this.lines[data.index].isEdit = !this.lines[data.index].isEdit
    }

    protected addLine () {
      this.lines = this.lines.map(item => ({
        ...item,
        isEdit: false
      }))
      if (this.lines.length >= this.services.length) return false
      this.lines.push({
        isEdit: true,
        id: null,
        service: {},
        volume: null,
        unit_of_measurement: null,
        rate: null,
        refund: null,
        currency: null,
        remarks: null,
        created_user: AuthModule.user.id
      })
    }

    protected deleteLine (index: number) {
      this.lines.splice(index, 1)
    }

    protected async generateInquiryNumber () {
      const date: any = new Date()
      const currentYear = date.getFullYear()
      const clientName = this.header.client_code.split('/')[0]
      const runningNumber = await InquiryService.getRunningNumber()
      this.header.inquiry_number = `${clientName}/${currentYear}/INQ/${runningNumber.data}`
    }

    protected save () {
      this.$v.$touch()
      if (this.$v.$invalid) return false
      this.header.status = 'pending'
      this.header.created_user = AuthModule.user.id
      if (this.isEditForm) {
        this.update()
      } else {
        this.create()
      }
    }

    private create () {
      const obj = {
        header: this.header,
        lines: this.lines
      }
      InquiryService.create(obj).then((response) => {
        // AppModule.message(response.data.status)
        this.$router.push({
          name: 'Inquiries'
        })
      }).catch(error => {
        this.setError(error)
      })
    }

    private update () {
      const obj = {
        header: this.header,
        lines: this.lines
      }
      InquiryService.update(obj).then((response) => {
        // AppModule.message(response.data.status)
        this.isEditForm = false
        this.$router.push({
          name: 'Inquiries'
        })
      }).catch(error => {
        this.setError(error)
      })
    }

    private clear () {
      if (this.isEditForm) return false
      this.reset(this.header)
    }

    protected cancel () {
      this.$v.$reset()
      if (this.isEditForm) return false
      this.reset(this.header)
      this.lines = []
      this.addLine()
    }

    private setError (error: any) {
      throw new Error(error)
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

