
import {
  Component,
  Vue
} from 'vue-property-decorator'
import InquiryService from './services/inquiry-service'

  @Component({
    name: 'Inquiries',
    components: {}
  })
export default class Inquiries extends Vue {
    private perPage = 10
    private currentPage = 1
    private searchTable = null
    private inquiries: any = []
    private fields = [{
      key: '#',
      label: '#',
      thStyle: {
        width: '4%'
      },
      class: 'text-right'
    },
    {
      key: 'client_code',
      label: 'Client Code'
    },
    {
      key: 'inquiry_number',
      label: 'Inquiry No.'
    },
    {
      key: 'vessel_name',
      label: 'Vessel Name'
    },
    {
      key: 'vessel_arrival_date',
      label: 'Vessel Arrival Date'
    },
    {
      key: 'voyage_number',
      label: 'Voyage Number'
    },
    {
      key: 'port_name',
      label: 'Port Name'
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'version',
      label: 'Version'
    },
    {
      key: 'action',
      label: '',
      thStyle: {
        width: '10%'
      },
      class: 'text-center'
    }
    ]

    created () {
      this.populateInquiries()
    }

    get rows () {
      return this.inquiries.length
    }

    private async populateInquiries () {
      const reponse = await InquiryService.getInquires()
      this.inquiries = reponse.data
    }

    private edit (id: any) {
      this.$router.push({
        name: 'InquiryForm',
        params: {
          id: id
        }
      })
    }
}

