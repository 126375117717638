
import {
  Component,
  Vue
} from 'vue-property-decorator'
import ExpenseService from '../services/expense-service'
// import String from '@/mixins/string-mixins'

import {
  AppModule
} from '@/store/modules/AppModule'

import {
  AuthModule
} from '@/store/modules/AuthModule'

  @Component({
    name: 'VesselExpenseAddModal'
  })
export default class VesselExpenseAddModal extends Vue {
    protected isEdit = false
    private vessels: any = []

    // Cost ledger categories
    private categories: any = []

    private category = {
      description: null,
      code: null
    }

    private expense = {
      id: null,
      source_type: 'vessel',
      source_id: null,
      record_date: null,
      category_id: null,
      description: null,
      amount: null,
      ref_no: null,
      created_user: null
    }

    created () {
      this.populateVessels()
    }

    public showModal (id = 0, edit = false) {
      (this.$refs.expenseAddModal as any).show()
      if (edit) {
        this.isEdit = true
        this.populateExpenseModal(id)
      }
    }

    public hideModal () {
      (this.$refs.expenseAddModal as any).hide()
    }

    private populateCategories (vesselId: any) {
      this.categories = []
      ExpenseService.getCategories(vesselId).then((response: any) => {
        response.data.forEach((element: { id: any; category: any }) => {
          this.categories.push({
            value: element.id,
            text: element.category
          })
        })
      }).catch(error => {
        console.log(error)
      })
    }

    private populateVessels () {
      ExpenseService.getVesselCodes().then((response: any) => {
        response.data.forEach((element: { id: any; code: string; name: string }) => {
          this.vessels.push({
            value: element.id,
            text: element.code + ' - ' + element.name
          })
        })
      }).catch(error => {
        console.log(error)
      })
    }

    private populateExpenseModal (id: number) {
      ExpenseService.getExpense(id).then((response) => {
        this.expense = response.data
        this.populateCategories(response.data.source_id)
      }).catch(error => {
        console.log(error)
      })
    }

    private save () {
      this.expense.created_user = AuthModule.user.id
      if (this.isEdit) {
        this.update()
      } else {
        this.create()
      }
    }

    private create () {
      ExpenseService.create(this.expense).then((response) => {
        // AppModule.message(response.data.message);
        (this.$parent as any).populateExpenseTable()
        this.clear()
      }).catch(error => {
        console.log(error)
      })
    }

    private update () {
      //   CrewService.update(this.crew).then((response: any) => {
      //     AppModule.message(response.data.message)
      //     this.cancel()
      //   }).catch(error => {
      //     AppModule.message(error.data.message)
      //   })
    }

    private clear () {
      this.reset(this.expense)
    }

    private close () {
      this.clear()
      this.hideModal()
      //   this.isEdit = false
      //   this.reset(this.crew)
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

