
import {
  Component,
  Vue
} from 'vue-property-decorator'
import ExpenseService from './services/expense-service'
// import String from '@/mixins/string-mixins'
import ExpenseAddModal from './components/ExpenseAddModal.vue'
import ExpenseEndModal from './components/ExpenseEndModal.vue'
import {
  AppModule
} from '@/store/modules/AppModule'

  @Component({
    name: 'VesselExpense',
    components: {
      ExpenseAddModal,
      ExpenseEndModal
    }
  })
export default class VesselExpense extends Vue {
    private fields = [{
      key: 'id',
      label: '#',
      sortable: false
    },
    {
      key: 'category',
      label: 'Category',
      sortable: false
    },
    {
      key: 'amount',
      label: 'amount',
      sortable: false
    },
    {
      key: 'version',
      label: 'Record Version',
      sortable: false
    },
    {
      key: 'created_date',
      label: 'Created Date',
      sortable: false
    },
    {
      key: 'actions',
      label: 'actions',
      sortable: false
    }]

    private expenses: any = []
    private sortBy: any = null
    private clFields = {
      Code: 'code',
      'Sub Code': 'sub',
      Description: 'description',
      Amount: 'amount'
    }

    private vessels: any = []

    private clData = [{
      code: '1.1.0.0',
      sub: '',
      description: 'Crew ReMmunerations ',
      amount: ''
    },
    {
      code: '',
      sub: '1.1.1.0',
      description: 'Crew wages',
      amount: '804779'
    }
    ]

    created () {
      // this.populateExpenseTable()
      this.populateVessels()
    }

    get sortOptions () {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return {
            text: f.label,
            value: f.key
          }
        })
    }

    private populateVessels () {
      ExpenseService.getVesselCodes().then((response: any) => {
        response.data.forEach((element: {
          id: any;code: string;name: string
        }) => {
          this.vessels.push({
            value: element.id,
            text: element.code + ' - ' + element.name
          })
        })
      }).catch(error => {
        console.log(error)
      })
    }

    private openAddModal () {
      (this.$refs.expAddModal as any).showModal()
    }

    private openEndModal () {
      (this.$refs.expEndModal as any).showModal()
    }

    public async populateExpenseTable (vesselId: any) {
      const reponse = await ExpenseService.getExpenses(vesselId)
      this.expenses = reponse.data
    }

    private edit (id: number) {
      (this.$refs.expAddModal as any).showModal(id, true)
    }
}

