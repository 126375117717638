import APIService from '@/services/api-service'
const resource = '/finance'

class TableService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getFiles () {
    return this.connector.get(`${resource}/all`)
  }

  public download (document: any) {
    return this.connector.post(`${resource}/download`, document, { responseType: 'blob' })
  }
}

export default new TableService()
