import APIService from '@/services/api-service'
const resource = '/job'
const subResource = 'payment'

class PaymentService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public savePaymentVoucher (data: any) {
    return this.connector.post(`${resource}/${subResource}/save`, data)
  }

  public getPaymentVouchers () {
    return this.connector.get(`${resource}/${subResource}/vouchers`)
  }

  public getVoucher () {
    return this.connector.get('/voucher/details')
  }
}

export default new PaymentService()
