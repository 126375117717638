
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  AuthModule
} from '@/store/modules/AuthModule'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import {
  ToastModule
} from '@/store/modules/ToastModule'
import UploadService from '../services/upload-service'
import TableService from '../services/table-service'
import {
  NotifyModule
} from '@/store/modules/NotifyModule'
import {
  API_URL
} from '@/config/env'
  @Component({
    name: 'Ledger',
    components: {}
  })
export default class Ledger extends Vue {
    protected isLoader = false
    protected isBusy = false
    protected perPage = 10
    protected pageOptions: any = [10, 25, 50, 100]
    protected currentPage = 1
    protected ledgers: any = []
    protected searchQuery = null
    protected upload: any = {
      id: null,
      name: null,
      file: null
    }

    protected fields = [{
      key: 'date',
      label: 'Date',
      sortable: true,
      thStyle: {
        width: '15%'
      }
    },
    {
      key: 'media_file',
      label: 'Ledger',
      thStyle: {
        width: '70%'
      }
    },
    {
      key: 'created_user',
      label: 'Created User',
      thStyle: {
        width: '15%'
      }
    }
    ]

    created () {
      this.boot()
    }

    protected boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.populate()
    }

    protected add () {
      (this.$refs.AddLedger as any).show()
    }

    protected hide () {
      (this.$refs.AddLedger as any).hide()
    }

    protected async populate () {
      this.isBusy = true
      const response = await TableService.getFiles()
      this.ledgers = response.data.map(item => ({
        ...item,
        media_file: item.media_file.split('/').pop(),
        file_path: API_URL + '/' + item.media_file,
        isEdit: false
      }))
      this.isBusy = false
    }

    protected uploadDocument () {
      this.isLoader = true
      const formData = new FormData()

      formData.append('file_name', this.upload.name)
      formData.append('document', this.upload.file)
      formData.append('created_user', AuthModule.user.fullname)
      UploadService.upload(formData).then((response) => {
        this.isLoader = false
        ToastModule.message(response.data.message)
        this.populate()
        this.hide()
        this.reset(this.upload)
      }).catch(error => {
        ToastModule.message(error.response.data.message)
      })
    }

    protected downloadDocument (file: any) {
      TableService.download(file).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], {
          type: 'application/octet-stream'
        }))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', file.media_file)
        document.body.appendChild(link)
        link.click()
      }).catch(error => {
        console.log(error)
        NotifyModule.set("We couldn't find the file you were looking for. Please try again.")
      })
    }

    protected reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

