
import {
  Component,
  Vue
} from 'vue-property-decorator'
import CrewService from '../services/crew-service'
import ExpenseService from '../services/expense-service'
import { AppModule } from '@/store/modules/AppModule'
import { AuthModule } from '@/store/modules/AuthModule'
import { Validations } from 'vuelidate-property-decorators'
// import { useVuelidate } from 'vuelidate/core'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

  @Component({
    name: 'CrewMember',
    components: {
      // Notify
    }
  })

export default class CrewMember extends Vue {
    // private d = useVuelidate
    private tabIndex = 1
    private isEdit = false
    private isContractPeriodOther = false
    private vessels: any = []
    private memberId: any = null

    public ranks: any = [{
      value: null,
      text: 'Please Select'
    },
    {
      value: 'MASTER',
      text: 'MASTER'
    },
    {
      value: 'DECK TR',
      text: 'DECK TR'
    },
    {
      value: 'CHEIF ENGINEER',
      text: 'CHEIF ENGINEER'
    },
    {
      value: 'CHEIF OFFICER',
      text: 'CHEIF OFFICER'
    },
    {
      value: 'GENERAL PURPOSE (G/P)',
      text: 'GENERAL PURPOSE (G/P)'
    },
    {
      value: '2ND ENGINEER',
      text: '2ND ENGINEER'
    },
    {
      value: 'TRAINEE ENG RATING',
      text: 'TRAINEE ENG RATING'
    },
    {
      value: 'DECK RATING',
      text: 'DECK RATING'
    },
    {
      value: 'TRAINEE COOK',
      text: 'TRAINEE COOK'
    },
    {
      value: 'TRAINEE SEAMAN',
      text: 'TRAINEE SEAMAN'
    },
    {
      value: 'OILER',
      text: 'OILER'
    },
    {
      value: 'SEAMAN',
      text: 'SEAMAN'
    }
    ]

    public nationalities: any = [{
      value: null,
      text: 'Please Select'
    },
    {
      value: 'Sri Lankan',
      text: 'Sri Lankan'
    }
    ]

    public contractPeriods: any = [{
      value: null,
      text: 'Please Select'
    },
    {
      value: 6,
      text: '6 Months'
    },
    {
      value: 9,
      text: '9 Months'
    },
    {
      value: 12,
      text: '12 Months'
    },
    {
      value: 'plus-minus',
      text: 'Plus-Minus'
    }]

    private statuses:any = [{
      value: 1,
      text: 'Active'
    },
    {
      value: null,
      text: 'Inactive'
    }]

    private allowanceTypes: any = []

    private allowances: any = []
    private allowance: any = {
      allowance_type_id: null,
      amount: null,
      source_id: null,
      source_type: null,
      created_user: null
    }

    private deductionTypes: any = [{
      value: null,
      text: 'Please Select'
    },
    {
      value: 1,
      text: 'Salary Advance'
    }
    ]

    private deduction: any = {
      deduction_type_id: null,
      amount: null,
      created_user: null
    }

    private deductions: any = []

    private crew: any = {
      vessel_id: null,
      name: null,
      rank: null,
      nationality: null,
      date_of_birth: null,
      nic_no: null,
      passport_no: null,
      passport_expiry_date: null,
      cdc_no: null,
      cdc_expiry_date: null,
      created_user: null
    }

    private contract: any = {
      joining_date: null,
      contract_period: null,
      sign_off_date: null
    }

    @Validations()
    validations = {
      crew: {
        name: {
          required,
          minLength: minLength(4)
        },
        nic_no: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(20)
        },
        passport_no: {
          required
        },
        passport_expiry_date: {
          required
        },
        cdc_no: {
          required
        },
        cdc_expiry_date: {
          required
        },
        joining_date: {
          required
        },
        contract_period: {
          required
        },
        no_of_days: {
          required
        },
        sign_off_date: {
          required
        }
      }
    }

    private salary: any = {
      basic_salary: null,
      allowances: 0,
      deductions: 0,
      per_day_rate: null,
      total_to_be_paid: null,
      created_user: null
    }

    private bank: any = {
      source_type: 'vessel',
      account_name: null,
      account_number: null,
      bank: null,
      branch: null
    }

    created () {
      this.boot()
    }

    private boot () {
      this.populateVessels()
      this.getAllowanceTypes()

      if (this.$route.params.id) {
        this.isEdit = true
        this.memberId = this.$route.params.id
        this.populateMember(this.$route.params.id)
      }

      this.isEdit = false
    }

    private calculatePerDayRate () {
      return parseInt(this.salary.basic_salary) / 30
    }

    private calculateTotalToBePaid () {
      this.salary.per_day_rate = this.calculatePerDayRate()
      this.salary.total_to_be_paid = parseInt(this.salary.basic_salary) + parseInt(this.salary.allowances) - parseInt(
        this.salary.deductions)
    }

    private removeDecimalPoint (event: {
      target: {
        value: string
      }
    }) {
      event.target.value = event.target.value.replace(/[^0-9]*/g, '')
    }

    private noDecimalInput (event: {
      target: {
        value: string
      }
    }) {
      event.target.value = event.target.value.replace(/[^0-9]*/g, '')
    }

    private setContractPeriod () {
      if (this.crew.contract_period === 'other') this.isContractPeriodOther = true
      else this.crew.sign_off_date = this.calculateSignOffDate(this.crew.joined_date, this.crew.contract_period)
    }

    private calculateSignOffDate (joinedDate: string, contractPeriod: any) {
      const signOffDate = new Date(joinedDate)
      signOffDate.setMonth(signOffDate.getMonth() + +contractPeriod)
      return signOffDate.toISOString().split('T')[0]
    }

    private populateVessels () {
      ExpenseService.getVesselCodes().then((response: any) => {
        response.data.forEach((element: {
          id: any;code: string;name: string
        }) => {
          this.vessels.push({
            value: element.id,
            text: element.code + ' - ' + element.name
          })
        })
      }).catch(error => {
        console.log(error)
      })
    }

    private async populateMember (id: any) {
      const response = await CrewService.getMember(id)
      this.crew = response.data
      this.contract.joining_date = response.data.joining_date
      this.contract.contract_period = response.data.contract_period
      this.contract.sign_off_date = response.data.sign_off_date
      // eslint-disable-next-line no-debugger
      debugger
      this.populateAllowances()
      this.populateDeductions()
      this.populateSalary()
    }

    private saveBasic () {
      const memberObj = {
        member_id: this.memberId,
        member: this.crew
      }
      CrewService.saveBasic(memberObj).then((response) => {
        // AppModule.message(response.data.message)
        this.memberId = response.data.id
        // this.reset(this.crew)
      }).catch(error => {
        console.log(error)
      })
    }

    private saveContractPeriod () {
      const memberObj = {
        member_id: this.memberId,
        contract: this.contract
      }
      CrewService.saveContractPeriod(memberObj).then((response) => {
        // AppModule.message(response.data.message)
        this.memberId = response.data.id
        // this.reset(this.crew)
      }).catch(error => {
        console.log(error)
      })
    }

    private async edit () {
      this.isEdit = true
      this.tabIndex++
      // this.crew = response.data
    }

    private async getAllowanceTypes () {
      const response = await CrewService.getAllowanceTypes()
      response.data.forEach((element) => {
        this.allowanceTypes.push({
          value: element.id,
          text: element.name
        })
      })
    }

    private async populateAllowances () {
      this.calculateTotalAllowance()
      const response = await CrewService.getAllowances(this.memberId)
      this.allowances = response.data
    }

    private addAllowance () {
      this.allowance.created_user = AuthModule.user.id
      if (this.memberId === null) {
        // AppModule.message('Please create a member first')
        return false
      }
      this.allowance.source_type = 'crew'
      this.allowance.source_id = this.memberId
      CrewService.addAllowance(this.allowance).then((response) => {
        // AppModule.message(response.data.message)
        // this.memberId = response.data.id
        this.populateAllowances()
        this.reset(this.allowance)
      }).catch(error => {
        console.log(error)
      })
    }

    // private deleteAllowance (index: number, amount: any) {
    //   this.allowances.splice(index, 1)
    // }

    private clearAllowance () {
      this.reset(this.allowance)
    }

    private async calculateTotalAllowance () {
      // if (this.salary.allowances !== 0) return false
      const response = await CrewService.getTotalAllowance(this.memberId)
      this.salary.allowances = response.data
    }

    private async populateDeductions () {
      this.calculateTotalDeductions()
      const response = await CrewService.getDeductions(this.memberId)
      this.deductions = response.data
    }

    private addDeduction () {
      this.deduction.created_user = AuthModule.user.id
      if (this.memberId === null) {
        // AppModule.message('Please create a member first')
        return false
      }
      this.deduction.source_type = 'crew'
      this.deduction.source_id = this.memberId

      CrewService.addDeduction(this.deduction).then((response) => {
        // AppModule.message(response.data.message)
        // this.memberId = response.data.id
        this.populateDeductions()
        this.reset(this.deduction)
      }).catch(error => {
        console.log(error)
      })
    }

    private deleteDeduction (index: number) {
      this.deductions.splice(index, 1)
    }

    private clearDeduction () {
      this.reset(this.deductions)
    }

    private async calculateTotalDeductions () {
      // if (this.salary.deductions !== 0) return false
      const response = await CrewService.getTotalDeductions(this.memberId)
      this.salary.deductions = response.data
    }

    private async populateSalary () {
      const response = await CrewService.getMemberSalary(this.memberId)
      this.salary = response.data
    }

    private saveSalary () {
      this.salary.created_user = AuthModule.user.id
      if (this.memberId === null) {
        // AppModule.message('Please create a member first')
        return false
      }
      this.salary.crew_id = this.memberId

      const salaryObj = {
        member_id: this.memberId,
        salary: this.salary
      }

      CrewService.saveSalary(salaryObj).then((response) => {
        // AppModule.message(response.data.message)
        // this.memberId = response.data.id
      }).catch(error => {
        console.log(error)
      })
    }

    private cancel () {
      this.isEdit = false
      this.reset(this.crew)
      this.reset(this.salary)
      this.reset(this.bank)
      this.tabIndex = 1
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

