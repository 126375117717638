import APIService from '@/services/api-service'
const base = '/vessel'
const child = 'expense'

class ExpenseService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public create (data: any) {
    return this.connector.post(`${base}/${child}/create`, data)
  }

  public getCategories (vesselId: any) {
    return this.connector.get(`${base}/${child}/categories/${vesselId}`)
  }

  public getVesselCodes () {
    return this.connector.get(`${base}/codes`)
  }

  public getExpense (vesselId: any) {
    return this.connector.get(`${base}/expense/${vesselId}`)
  }

  public getExpenses (vesselId: any) {
    return this.connector.get(`${base}/expense/vessel/${vesselId}`)
  }

  public endMonth (data: any) {
    return this.connector.post(`${base}/${child}/monthend`, data)
  }
}

export default new ExpenseService()
