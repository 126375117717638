import APIService from '@/services/api-service'
const resource = '/vessel/crew'

class CrewService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getCrewMembers () {
    return this.connector.get(`${resource}/all`)
  }

  public saveBasic (data: any) {
    return this.connector.post(`${resource}/save/basic`, data)
  }

  public saveContractPeriod (data: any) {
    return this.connector.post(`${resource}/save/contract`, data)
  }

  public getAllowanceTypes () {
    return this.connector.get(`${resource}/allowance/types`)
  }

  public getAllowances (id: number) {
    return this.connector.get(`${resource}/allowances/${id}`)
  }

  public addAllowance (data: any) {
    return this.connector.post(`${resource}/save/allowance`, data)
  }

  public getTotalAllowance (id: number) {
    return this.connector.get(`${resource}/allowance/total/${id}`)
  }

  public getDeductionTypes () {
    return this.connector.get(`${resource}/deduction/types`)
  }

  public getDeductions (id: number) {
    return this.connector.get(`${resource}/deductions/${id}`)
  }

  public addDeduction (data: any) {
    return this.connector.post(`${resource}/save/deduction`, data)
  }

  public getTotalDeductions (id: number) {
    return this.connector.get(`${resource}/deduction/total/${id}`)
  }

  public getMember (id: number) {
    return this.connector.get(`${resource}/member/${id}`)
  }

  public getMemberSalary (id: number) {
    return this.connector.get(`${resource}/salary/${id}`)
  }

  public saveSalary (data: any) {
    return this.connector.post(`${resource}/save/salary`, data)
  }
}

export default new CrewService()
