import APIService from '@/services/api-service'
const resource = '/inquiry'

class InquiryService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public create (data: any) {
    return this.connector.post(`${resource}/create`, data)
  }

  public getInquires () {
    return this.connector.get(`${resource}/all`)
  }

  public getRunningNumber () {
    return this.connector.get(`${resource}/running/number`)
  }

  public getInquiry (id: any) {
    return this.connector.get(`${resource}/${id}`)
  }

  public getInquiryByNumber (data: any) {
    return this.connector.post(`${resource}/modal`, data)
  }

  public update (data: any) {
    return this.connector.put(`${resource}/update`, data)
  }

  public clientCodes () {
    return this.connector.get('/client/codes')
  }

  public getPortNames () {
    return this.connector.get('/port/names')
  }

  public getServices () {
    return this.connector.get('/services')
  }

  public getSerivceRate (clientCode, serviceId) {
    return this.connector.post(`${resource}/rate`, { client_code: clientCode, service_id: serviceId })
  }
}

export default new InquiryService()
