
import {
  Component,
  Vue
} from 'vue-property-decorator'
import ExpenseService from '../services/expense-service'
// import String from '@/mixins/string-mixins'
import Months from '@/data/months.json'

  @Component({
    name: 'VesselExpenseEndModal',
    components: {
      // Excel
    }
  })
export default class VesselExpenseEndModal extends Vue {
    private months = Months

    private monthEnd: any = null

    private clFields = {
      Code: 'code',
      'Sub Code': 'sub',
      Description: 'description',
      Amount: 'amount'
    }

    private clData: any = []

    created () {
      // this.populateVessels()
    }

    public showModal () {
      (this.$refs.expenseEndModal as any).show()
    }

    public hideModal () {
      (this.$refs.expenseEndModal as any).hide()
    }

    // private populateVessels () {
    //   ExpenseService.getVesselCodes().then((response: any) => {
    //     response.data.forEach((element: {
    //       id: any;code: string;name: string
    //     }) => {
    //       this.vessels.push({
    //         value: element.id,
    //         text: element.code + ' - ' + element.name
    //       })
    //     })
    //   }).catch(error => {
    //     console.log(error)
    //   })
    // }

    public async submit () {
      const expenses = await ExpenseService.getExpenses(12)
      //   const temp: any = [{
      //     code: '1.1.0.0',
      //     description: 'Crew ReMmunerations',
      //     data: []
      //   }]
      // const org: any = []
      const data = { month: this.monthEnd }
      ExpenseService.endMonth(data).then((response) => {
        expenses.data.forEach((expense: any) => {
          this.clData.push({
            code: expense.code,
            sub: expense.sub,
            description: expense.description,
            amount: expense.sum
          })
        })
        // this.clData = temp
        console.log(response)
      }).catch(error => {
        console.log(error)
      })
    }

    // private create () {
    //   console.log(this.$parent)
    //   ExpenseService.create(this.expense).then((response) => {
    //     AppModule.message(response.data.message);
    //     (this.$parent as any).populateExpenseTable()
    //     this.clear()
    //   }).catch(error => {
    //     console.log(error)
    //   })
    // }

    // private async edit (id: number) {
    //   this.isEdit = true
    //   // this.crew = response.data
    // }

    private update () {
      //   CrewService.update(this.crew).then((response: any) => {cls
      //     AppModule.message(response.data.message)
      //     this.cancel()
      //   }).catch(error => {
      //     AppModule.message(error.data.message)
      //   })
    }

    private clear () {
      // this.reset(this.expense)
    }

    private close () {
      this.clear()
      this.hideModal()
      //   this.isEdit = false
      //   this.reset(this.crew)
    }

    private reset (obj: any) {
      for (const key in obj) {
        obj[key] = null
      }
    }
}

