import APIService from '@/services/api-service'
const resource = '/finance'

class UploadService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public upload (data: any) {
    return this.connector.post(`${resource}/upload`, data)
  }
}

export default new UploadService()
